body {
  color: black;
  height: 100dvh;
  width: 100dvw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin: 0;
  overflow: hidden;
}
main {
  width: 100%;
}
.item-container {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  padding: 0;

  height: 80%;
  list-style: none;
  flex-wrap: wrap;
  gap: 15px;
  font-family: sans-serif;
}
.item {
  width: 20%;
  min-width: 230px;
  max-width: 230px;
  background-color: #65616152;
  min-height: 350px;
  box-shadow: 0px 0px 5px;
  height: 100px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  color: white;
  justify-content: space-between;
  align-items: center;
  backdrop-filter: blur(10px);
}
#selectRegionOpt {
  font-size: larger;
  padding: 10px;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: transparent;
  color: white;
}
#selectRegionOpt option {
  background-color: #5b5b5b;
}
.item p {
  text-align: center;
}
.weather-icon {
  width: 100px;
  height: 100px;
}
.weather-icon img {
  width: 100%;
  height: 100%;
}
.load-text {
  display: none;
  animation: glow 1s linear infinite;
}
.bkground {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  background: linear-gradient(45deg, rgb(249, 175, 175), rgb(116, 144, 246));
}

@keyframes glow {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}
@media (max-width: 500px) {
}
