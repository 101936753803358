body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#root{
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  justify-content: space-between;
  overflow: auto;
  color: white;
}

footer{
  font-size: 0.8em;
  font-style: italic;
}